/* thai */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Kanit Regular'), local('Kanit-Regular'), url(../public/fonts/Kanit/Kanit-thai.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Kanit Regular'), local('Kanit-Regular'), url(../public/fonts/Kanit/Kanit-vietnamese.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Kanit Regular'), local('Kanit-Regular'), url(../public/fonts/Kanit/Kanit-latin-ext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Kanit Regular'), local('Kanit-Regular'), url(../public/fonts/Kanit/Kanit-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
    box-sizing: border-box;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    user-select: none;
    // overflow: hidden;
}

html, body {
    margin: 0;
    font-family: 'Kanit', sans-serif;
}

.chooseFile {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    input {
        display: none;
    }

    button {
        background-color: #fff;
        border: 1px solid #000;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
    }

}

.sub {
    position: relative;
    width: 100%;
    height: 100%;
}

.maze-map {
    margin: auto;
    min-width: 100%;
    width: 100vw;
    text-align: center;
    white-space: nowrap;

    .row {
        margin: auto;
        display: flex;
        width: fit-content;
        height: 16px;
        justify-content: center;
        transition-duration: 2s;

        .column {
            display: inline-block;
            width: 16px;
            min-width: 16px;
            height: 100%;
            font-size: 10px;
            text-align: center;
            border: 1px black solid;
            transition-duration: 2s;

            &.wall {
                background-color: black;
            }

            &.walked {
                background-color: #f2ee8c;
                border-color: grey;
            }

            &.queue {
                background-color: #22e5ff;
                border-color: grey;
                transition-duration: .1s;
            }

            &.path {
                background-color: blue;
                border-color: blue;
                color: white;
                transition-duration: .5s;
            }

            &.start {
                background-color: rgb(0, 189, 0);
                border-color: rgb(0, 189, 0);
                color: white;
            }

            &.exit {
                background-color: red;
                border-color: red;
                color: white;
            }

            
        }

        .column + .column {
            margin-left: 5px;    
        }
    }

    .row + .row {
        margin-top: 7px;
    }
}

.maze-map.cleanUp {
    .row {
        transition-duration: 1s;
        .column {
            color: transparent;
            font-size: 12px;
            border-color: white;
            transition-duration: 1s;

            &.wall {
                background-color: black;
                border-color: black;
            }

            &.walked {
                background-color: #fefff2;
                border-color: #d6d8c1;
                color: rgb(141, 141, 141);
                transition-duration: 1s;
            }

            &.queue {
                background-color: white;
                border-color: white;
                color: transparent;
                
            }

            &.path {
                background-color: blue;
                border-color: blue;
                color: white;
            }

            &.start {
                background-color: rgb(0, 189, 0);
                border-color: rgb(0, 189, 0);
                color: white;
            }

            &.exit {
                background-color: red;
                border-color: red;
                color: white;
            }
        }
    }
}

.maze-map.finish {
    .row {
        margin-top: 0;
        height: 23px;
        transition-duration: 1s;
        .column {
            width: 23px;
            // border: 0px black solid;
            margin-left: 0;
            font-size: 12px;
            transition-duration: 1s;

            
        }
    }
}